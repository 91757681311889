<template>
    <div id="page-user-list">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <div class="vx-row">
            <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="FileMinusIcon"
                icon-right
                :statistic="(countWD)"
                statisticTitle="จำนวนถอน/ครั้ง"
                color="warning"
              />
            </div>
            <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CheckSquareIcon"
                icon-right
                :statistic="(count_withdraw_success)"
                statisticTitle="ถอนสำเร็จ/ครั้ง"
                color="success"
              />
            </div>
  
            <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="MinusCircleIcon"
                icon-right
                :statistic="currency(withdraw)"
                statisticTitle="WITHDRAW BALANCE"
                color="danger"
              />
            </div>
          </div>
  
          <vx-card title="Filter">
            <div class="vx-row">
              <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
                <label class="vs-input--label">Start Date</label>
                <flat-pickr
                  class="w-full mt-4"
                  v-model="startDate"
                  :config="config"
                />
                <br />
              </div>
              <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
                <label class="vs-input--label">End Date</label>
                <flat-pickr
                  class="w-full mt-4"
                  v-model="endDate"
                  :config="config"
                />
                <br />
              </div>
              <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
                <label class="vs-input--label">Type</label>
                <!-- <v-select  v-model="month" :options="monthOptions" name="status"
                :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
                <vs-select class="w-full mt-4" v-model="filterType">
                  <vs-select-item
                    :key="index"
                    :value="item.val"
                    :text="item.name"
                    v-for="(item, index) in filterSelectType"
                  />
                </vs-select>
                <br />
              </div>
              <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
                <label class="vs-input--label">Status</label>
                <!-- <v-select  v-model="month" :options="monthOptions" name="status"
                :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
                <vs-select class="w-full mt-4" v-model="filterStatus">
                  <vs-select-item
                    :key="index"
                    :value="item.val"
                    :text="item.name"
                    v-for="(item, index) in filterSelectStatus"
                  />
                </vs-select>
                <br />
              </div>
              <div class="vx-col w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6">
                <label class="vs-input--label">จำนวนเงิน(ต่ำสุด-สูงสุด)</label>
  
                <vs-input
                  class="w-full mt-4"
                  type="number"
                  v-model="startAmont"
                />
                <br />
              </div>
              <label class="vs-input--label mt-12 mr-1">-</label>
              <div class="vx-col w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6">
                <label class="vs-input--label"></label>
                <vs-input class="w-full mt-4" type="number" v-model="endAmont" />
                <br />
              </div>
              <!-- <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
                <label class="vs-input--label">Text in table</label>
                <vs-input class="w-full mt-4" type="text" v-model="textSearch" />
                <br />
              </div> -->
            </div>
            <vs-button class="mr-2" color="success" @click="getDataTransaction()"
              >Search</vs-button
            >
            <vs-button color="primary" @click="clearSearchBox()">Clear</vs-button>
          </vx-card>
          <br />
  
          <vx-card title="Result">
            <vs-table
              stripe
              max-items="20"
              pagination
              :data="dataTransaction"
              search
            >
            <template slot="thead">
                  <vs-th>Datetime</vs-th>
                  <vs-th>USERNANE</vs-th>
                  <vs-th>CREDIT</vs-th>
                  <vs-th>STATUS</vs-th>
                  <vs-th>Info</vs-th>
                  <vs-th>BANK</vs-th>
                  <vs-th>ADMIN</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="tr.wd_datetime">
                      {{ moment(tr.wd_datetime).format("YYYY-MM-DD HH:mm:ss") }}
                    </vs-td>
                    <vs-td class="text-danger"
                      ><router-link
                        :to="`/member/${data[indextr].wd_username}`"
                        >{{ data[indextr].wd_username }}</router-link
                      ></vs-td
                    >
                    <vs-td :data="tr.wd_amount">
                      {{ currency(tr.wd_amount) }}
                    </vs-td>
                     <!-- Status -->
            <vs-td
              :class="
                data[indextr].wd_status == 0
                  ? 'text-success' :
                data[indextr].wd_status == 1
                  ? 'text-success'
                  : data[indextr].wd_status == 2 || data[indextr].wd_status == 3
                  ? 'text-warning'
                  : data[indextr].wd_status == 4
                  ? 'text-danger'
                  : data[indextr].wd_status == 100
                  ? 'text-warning'
                  : data[indextr].wd_status == 999 
                  ? 'text-danger'
                  : null
              "
            >
              {{ check_status(data[indextr].wd_status) }}
            </vs-td>
                    <vs-td :data="tr.wd_info">
                      {{ tr.wd_info }}
                    </vs-td>
                    <vs-td :data="tr.wd_bank_acc">
                      {{ tr.wd_bank_acc }}
                    </vs-td>
                    <vs-td :data="tr.wd_admin">
                      {{ tr.wd_admin }}
                    </vs-td>
                  </vs-tr>
                </template>
            </vs-table>
          </vx-card>
        </div>
      </div>
    </div>
  </template>
  
<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  components: {
    StatisticsCardLine,
    vSelect,
    flatPickr
  },
  data () {
    return {
      countWD:0.0,
      withdraw:0.0,
      count_withdraw_success :0.0,
      //textSearch: '',
      startAmont: '',
      endAmont: '',
      startDate: this.moment()
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: this.moment().format('YYYY-MM-DD HH:mm:ss'),
      filterType: 0,
      filterSelectType: [
        {
          val: 0,
          name: 'ALL',
          sentname: 'All'
        },
        {
          val: 1,
          name: 'BANK',
          sentname: 'Scb'
        },
        {
          val: 2,
          name: 'VIZPAY',
          sentname: 'Vizpay'
        },
        {
          val: 3,
          name: 'AKPAY',
          sentname: 'akpay'
        },
        {
          val:4,
          name:'HENGPAY',
          sentname:'Hengpay'
        },
        {
          val:5,
          name:'OMEGAPAY',
          sentname:'Omegapay'
        },
        {
          val:6,
          name:'POMPAY',
          sentname:'Pompay'
        }

      ],
      filterStatus: 0,
      filterSelectStatus: [
        {
          val: 0,
          status: 9999,
          name: 'ทั้งหมด',
          sentname: 'ทั้งหมด'
        },
        {
          val: 1,
          status: 0,
          name: 'รอทำรายการ',
          sentname: 'รอทำรายการ'
        },
        {
          val: 2,
          status: 1,
          name: 'ทำรายการสำเร็จ',
          sentname: 'ทำรายการสำเร็จ'
        },
        {
          val: 3,
          status: 2,
          name: 'คืนเครดิต',
          sentname: 'คืนเครดิต'
        },
        {
          val: 4,
          status: 3,
          name: 'ยกเลิกถอน',
          sentname: 'ยกเลิกถอน'
        },
        {
          val: 5,
          status: 999,
          name: 'โยกเงิน',
          sentname: 'โยกเงิน'
        }
      ],
      config: {
        enableTime: true
      },
      dataTransaction: []
    }
  },
  async mounted () {
    await this.getBalance()
    await this.getDataTransaction()
  },
  methods: {
    clearSearchBox () {
      this.startDate = this.moment()
        
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
      this.endDate = this.moment().format('YYYY-MM-DD HH:mm:ss')
      this.filterType = 0
      this.filterStatus = 0
      //this.textSearch = ''
      this.startAmont = ''
      this.endAmont = ''
    },
    async getBalance () {
      try {
        const getBalance = await axios
          .get(`/withdraw/countWithdrawdata/${this.startDate}/${this.endDate}`)
        this.countWD = getBalance.data.count_wd
        this.withdraw = getBalance.data.count_amount
        this.count_withdraw_success = getBalance.data.count_wd_succuss
      } catch (error) {
        console.error(error)
      }
    },
    async getDataTransaction () {
      await this.getBalance()
      await axios
        .post('/withdraw/withdrawlistv2', {
          status_search: this.filterSelectStatus[this.filterStatus].status,
          transaction_type_search: this.filterSelectType[this.filterType]
            .sentname,
          start_date: this.startDate,
          end_date: this.endDate,
          from_txn_amount_search: this.startAmont,
          to_txn_amount_search: this.endAmont
        })
        .then(response => (this.dataTransaction = response.data))
        .catch()
    },
    check_status (status) {
      if (status === 0) {
        return 'รอทำรายการ'
      } else if (status === 1) {
        return 'ทำรายการสำเร็จ'
      } else if (status === 2) {
        return 'คืนเครดิต'
      } else if (status === 3) {
        return 'ยกเลิกถอน'
      } else if (status === 100) {
        return 'กำลังประมวลผล'
      } else if (status === 999) {
        return 'โยกเงิน'
      } else {
        return 'เกิดข้อผิดพลาด'
      }
    },
    currency (amount) {
      amount = (amount * 1.0).toFixed(2)
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(amount)
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      }
    }
  }
}
</script>
  
  <style scoped>
  .b {
    border-radius: 5px;
    padding: 5px; /* Add padding here */
    color: white;
    background-color: #792cffde;
  }
  
  .b-success {
    background-color: #03c837de;
  }
  
  .b-failed {
    background-color: #ff0000de;
  }
  
  .b-create {
    background-color: #ffb300de;
  }
  </style>
  